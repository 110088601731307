var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import * as React from 'react';
import classNames from 'classnames';
import { composeRef } from 'rc-util/lib/ref';
import SearchOutlined from '@ant-design/icons/SearchOutlined';
import Input from './Input';
import Button from '../button';
import SizeContext from '../config-provider/SizeContext';
import { ConfigConsumer } from '../config-provider';
import { cloneElement } from '../_util/reactNode';
const Search = React.forwardRef((props, ref) => {
    const inputRef = React.useRef(null);
    const onChange = (e) => {
        const { onChange: customOnChange, onSearch: customOnSearch } = props;
        if (e && e.target && e.type === 'click' && customOnSearch) {
            customOnSearch(e.target.value, e);
        }
        if (customOnChange) {
            customOnChange(e);
        }
    };
    const onMouseDown = e => {
        var _a;
        if (document.activeElement === ((_a = inputRef.current) === null || _a === void 0 ? void 0 : _a.input)) {
            e.preventDefault();
        }
    };
    const onSearch = (e) => {
        var _a;
        const { onSearch: customOnSearch } = props;
        if (customOnSearch) {
            customOnSearch((_a = inputRef.current) === null || _a === void 0 ? void 0 : _a.input.value, e);
        }
    };
    const renderAddonAfter = (prefixCls, size) => {
        const { enterButton, disabled, addonAfter, loading } = props;
        const searchIcon = typeof enterButton === 'boolean' || typeof enterButton === 'undefined' ? (<SearchOutlined />) : null;
        const btnClassName = `${prefixCls}-button`;
        let button;
        const enterButtonAsElement = enterButton;
        const isAntdButton = enterButtonAsElement.type &&
            enterButtonAsElement.type.__ANT_BUTTON === true;
        if (isAntdButton || enterButtonAsElement.type === 'button') {
            button = cloneElement(enterButtonAsElement, Object.assign({ onMouseDown, onClick: onSearch, key: 'enterButton' }, (isAntdButton
                ? {
                    className: btnClassName,
                    size,
                }
                : {})));
        }
        else {
            button = (<Button className={btnClassName} type={enterButton ? 'primary' : undefined} size={size} disabled={disabled} key="enterButton" onMouseDown={onMouseDown} onClick={onSearch} loading={loading} icon={searchIcon}>
          {enterButton}
        </Button>);
        }
        if (addonAfter) {
            return [
                button,
                cloneElement(addonAfter, {
                    key: 'addonAfter',
                }),
            ];
        }
        return button;
    };
    const renderSearch = ({ getPrefixCls, direction }) => {
        const { prefixCls: customizePrefixCls, inputPrefixCls: customizeInputPrefixCls, className, size: customizeSize, suffix } = props, restProps = __rest(props, ["prefixCls", "inputPrefixCls", "className", "size", "suffix"]);
        delete restProps.onSearch;
        delete restProps.loading;
        delete restProps.enterButton;
        const prefixCls = getPrefixCls('input-search', customizePrefixCls);
        const inputPrefixCls = getPrefixCls('input', customizeInputPrefixCls);
        const getClassName = (size) => classNames(prefixCls, {
            [`${prefixCls}-rtl`]: direction === 'rtl',
            [`${prefixCls}-${size}`]: !!size,
        }, className);
        return (<SizeContext.Consumer>
        {size => (<Input ref={composeRef(inputRef, ref)} onPressEnter={onSearch} {...restProps} size={customizeSize || size} prefixCls={inputPrefixCls} addonAfter={renderAddonAfter(prefixCls, customizeSize || size)} suffix={suffix} onChange={onChange} className={getClassName(customizeSize || size)}/>)}
      </SizeContext.Consumer>);
    };
    return <ConfigConsumer>{renderSearch}</ConfigConsumer>;
});
Search.defaultProps = {
    enterButton: false,
};
Search.displayName = 'Search';
export default Search;
