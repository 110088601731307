import * as React from 'react';
import RcTextArea from 'rc-textarea';
import omit from 'omit.js';
import classNames from 'classnames';
import ClearableLabeledInput from './ClearableLabeledInput';
import { ConfigConsumer } from '../config-provider';
import { fixControlledValue, resolveOnChange } from './Input';
class TextArea extends React.Component {
    constructor(props) {
        super(props);
        this.focus = () => {
            this.resizableTextArea.textArea.focus();
        };
        this.saveTextArea = (textarea) => {
            this.resizableTextArea = textarea === null || textarea === void 0 ? void 0 : textarea.resizableTextArea;
        };
        this.saveClearableInput = (clearableInput) => {
            this.clearableInput = clearableInput;
        };
        this.handleChange = (e) => {
            this.setValue(e.target.value);
            resolveOnChange(this.resizableTextArea.textArea, e, this.props.onChange);
        };
        this.handleReset = (e) => {
            this.setValue('', () => {
                this.focus();
            });
            resolveOnChange(this.resizableTextArea.textArea, e, this.props.onChange);
        };
        this.renderTextArea = (prefixCls, bordered) => {
            const { showCount, className, style } = this.props;
            return (<RcTextArea {...omit(this.props, ['allowClear', 'bordered', 'showCount'])} className={classNames({
                [`${prefixCls}-borderless`]: !bordered,
                [className]: className && !showCount,
            })} style={showCount ? null : style} prefixCls={prefixCls} onChange={this.handleChange} ref={this.saveTextArea}/>);
        };
        this.renderComponent = ({ getPrefixCls, direction }) => {
            var _a;
            let value = fixControlledValue((_a = this.state) === null || _a === void 0 ? void 0 : _a.value);
            const { prefixCls: customizePrefixCls, bordered = true, showCount = false, maxLength, className, style, } = this.props;
            const prefixCls = getPrefixCls('input', customizePrefixCls);
            // Max length value
            const hasMaxLength = Number(maxLength) > 0;
            value = hasMaxLength ? value.slice(0, maxLength) : value;
            // TextArea
            let textareaNode = (<ClearableLabeledInput {...this.props} prefixCls={prefixCls} direction={direction} inputType="text" value={value} element={this.renderTextArea(prefixCls, bordered)} handleReset={this.handleReset} ref={this.saveClearableInput} triggerFocus={this.focus} bordered={bordered}/>);
            // Only show text area wrapper when needed
            if (showCount) {
                const valueLength = [...value].length;
                const dataCount = `${valueLength}${hasMaxLength ? ` / ${maxLength}` : ''}`;
                textareaNode = (<div className={classNames(`${prefixCls}-textarea`, {
                    [`${prefixCls}-textarea-rtl`]: direction === 'rtl',
                }, `${prefixCls}-textarea-show-count`, className)} style={style} data-count={dataCount}>
          {textareaNode}
        </div>);
            }
            return textareaNode;
        };
        const value = typeof props.value === 'undefined' ? props.defaultValue : props.value;
        this.state = {
            value,
            // eslint-disable-next-line react/no-unused-state
            prevValue: props.value,
        };
    }
    static getDerivedStateFromProps(nextProps, { prevValue }) {
        const newState = { prevValue: nextProps.value };
        if (nextProps.value !== undefined || prevValue !== nextProps.value) {
            newState.value = nextProps.value;
        }
        return newState;
    }
    setValue(value, callback) {
        if (this.props.value === undefined) {
            this.setState({ value }, callback);
        }
    }
    blur() {
        this.resizableTextArea.textArea.blur();
    }
    render() {
        return <ConfigConsumer>{this.renderComponent}</ConfigConsumer>;
    }
}
export default TextArea;
